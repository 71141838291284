<template>
  <router-view />
</template>

<script>
export default {
  name: 'index',
  data: () => ({
    auth: false
  })
};
</script>
